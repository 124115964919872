import React, { useRef, useState } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { CaretRightOutlined } from '@ant-design/icons';
import Image from 'next/image';
const FeaturesContent = ({
  hairstyleTotal,
  bloggerTotal,
}: {
  hairstyleTotal?: any;
  bloggerTotal?: any;
}) => {
  const { t } = useTranslation();
  const [videoPlaying1, setVideoPlaying1] = useState(false);
  const [videoPlaying2, setVideoPlaying2] = useState(false);

  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);

  const playAction1 = () => {
    if (videoRef1.current) {
      !videoRef1.current.paused ? videoRef1.current.pause() : videoRef1.current.play();
      setVideoPlaying1(!videoRef1.current.paused);
    }
  };

  const playAction2 = () => {
    if (videoRef2.current) {
      !videoRef2.current.paused ? videoRef2.current.pause() : videoRef2.current.play();
      setVideoPlaying2(!videoRef2.current.paused);
    }
  };

  return (
    <div className={styles.content}>
      <div>
        <div>{t('Hairpaca Features')}</div>
        <div>{t('Always be the trendsetter in your social circle!')}</div>
      </div>
      <div className={styles.mainCon}>
        <div className={styles.firstRow}>
          <div className={styles.left}>
            <div>{hairstyleTotal}</div>
            <div>{t('Hairstyles to Try On')}</div>
            <div>{t('No salon visits needed, no actual haircuts. All in just seconds.')}</div>
          </div>
          <div className={styles.right}>
            <div className={styles.icon} style={{ position: 'relative' }}>
              <video
                key={1111}
                ref={videoRef1}
                src={`https://res-ins.pumpsoul.com/vedio/hairstyle-try-on.mp4`}
                width={'100%'}
                onClick={() => playAction1()}
                autoPlay={false}
                preload="none"
                muted
                playsInline
              />
              {!videoPlaying1 && (
                <div
                  className={styles.playInfoContainer}
                  style={{ backgroundImage: `url(/images/hairstyle/home-video-cover-1.png)` }}
                  onClick={() => playAction1()}
                >
                  <div className={styles.playContainer}>
                    <CaretRightOutlined style={{ fontSize: '30px', color: '#FFF' }} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.secondRow}>
          <div className={styles.right}>
            <div>
              <span>{bloggerTotal}</span>
              <span>{t('Hair')}</span>
            </div>
            <div>{t('Influencers to Follow')}</div>
            <div>
              {t(
                'Easily try on the latest hairstyles from influencers. See how you look with new styles before making any changes.',
              )}
            </div>
          </div>
          <div className={styles.left}>
            <div className={styles.icon} style={{ position: 'relative' }}>
              <video
                key={2222}
                ref={videoRef2}
                src={`https://res-ins.pumpsoul.com/vedio/Influencers-to-Follow.mp4`}
                width={'100%'}
                onClick={() => playAction2()}
                autoPlay={false}
                preload="none"
                muted
                playsInline
              />
              {!videoPlaying2 && (
                <div
                  className={styles.playInfoContainer}
                  style={{ backgroundImage: `url(/images/hairstyle/home-video-cover-2.png)` }}
                  onClick={() => playAction2()}
                >
                  <div className={styles.playContainer}>
                    <CaretRightOutlined style={{ fontSize: '30px', color: '#FFF' }} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.right}>
            <div>
              <span>{bloggerTotal}</span>
              <span>{t('Hair')}</span>
            </div>
            <div>{t('Influencers to Follow')}</div>
            <div>
              {t(
                'Easily try on the latest hairstyles from influencers. See how you look with new styles before making any changes.',
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contentEnd}>
        <Image
          src="/images/hairstyle/index-icon-left.png"
          alt=""
          width={100}
          height={100}
          className={styles.img}
        />
        <span>{t('Discover more and become the envy of everyone around you.')}</span>
        <Image
          src="/images/hairstyle/index-icon-right.png"
          alt=""
          width={100}
          height={100}
          className={styles.img}
        />
      </div>
    </div>
  );
};

export default FeaturesContent;
